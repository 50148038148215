import { ObjGen } from 'objgen/objgen.js';
import { GenEdder } from './GenEdder.js';

const is = require('is_js');

const htmlElements = {
  "a" : true,
  "abbr" : true,
  "acronym" : true,
  "address" : true,
  "applet" : true,
  "area" : true,
  "article" : true,
  "aside" : true,
  "audio" : true,
  "b" : true,
  "base" : true,
  "basefont" : true,
  "bdi" : true,
  "bdo" : true,
  "bgsound" : true,
  "big" : true,
  "blink" : true,
  "blockquote" : true,
  "body" : false,
  "br" : true,
  "button" : true,
  "canvas" : true,
  "caption" : true,
  "center" : true,
  "cite" : true,
  "code" : true,
  "colgroup" : true,
  "command" : true,
  "data" : true,
  "datalist" : true,
  "dd" : true,
  "del" : true,
  "details" : true,
  "dfn" : true,
  "dir" : true,
  "div" : true,
  "dl" : true,
  "dt" : true,
  "em" : true,
  "embed" : true,
  "fieldset" : true,
  "figcaption" : true,
  "figure" : true,
  "font" : true,
  "footer" : true,
  "form" : true,
  "frame" : true,
  "frameset" : true,
  "h1" : true,
  "h2" : true,
  "h3" : true,
  "h4" : true,
  "h5" : true,
  "h6" : true,
  "head" : true,
  "header" : true,
  "hgroup" : true,
  "hr" : true,
  "html" : false,
  "i" : true,
  "iframe" : true,
  "img" : true,
  "input" : true,
  "ins" : true,
  "isindex" : true,
  "kbd" : true,
  "keygen" : true,
  "label" : true,
  "legend" : true,
  "li" : true,
  "link" : true,
  "listing" : true,
  "map" : true,
  "mark" : true,
  "marquee" : true,
  "menu" : true,
  "meta" : true,
  "meter" : true,
  "nav" : true,
  "nobr" : true,
  "noframes" : true,
  "noscript" : true,
  "object" : true,
  "ol" : true,
  "optgroup" : true,
  "option" : true,
  "output" : true,
  "p" : true,
  "param" : true,
  "plaintext" : true,
  "pre" : true,
  "progress" : true,
  "q" : true,
  "rp" : true,
  "rt" : true,
  "ruby" : true,
  "s" : true,
  "samp" : true,
  "script" : false,
  "section" : true,
  "select" : true,
  "small" : true,
  "source" : true,
  "spacer" : true,
  "span" : true,
  "strike" : true,
  "strong" : true,
  "style" : true,
  "sub" : true,
  "summary" : true,
  "sup" : true,
  "table" : true,
  "tbody" : true,
  "td" : true,
  "textarea" : true,
  "tfoot" : true,
  "th" : true,
  "thead" : true,
  "time" : true,
  "title" : true,
  "tr" : true,
  "track" : true,
  "tt" : true,
  "u" : true,
  "ul" : true,
  "var" : true,
  "video" : true,
  "wbr" : true,
  "xmp" : true
};

class Html extends GenEdder {

  constructor(props) {
    super(props);
    this.hasPreview = true;
  }

  get title() {
    return 'HTML';
  }

  get preview() {
    return { __html: this.state.genCode };
  }

  get syntaxLanguage() {
    return 'html';
  }

  get demoModel() {
    let demo =
      '// This is a comment!\\n' +
      '// HTML generator quick tips:\\n' +
      '// - Each line with content will generate a HTML element\\n' +
      '// - Generate elements by just using their tag names\\n' +
      '// - Elements are nested using tabs and/or spaces\\n' +
      '// - Element content (inner text) is specified using an equal sign \'=\'\\n' +
      '// - Specify one or more class attributes after the tag name\\n' +
      '// - Class names can optionally be indicated using a dot prefix, \'.some-class\'\\n' +
      '// - Element ids are assigned using a hash prefix, \'#someElementId\'\\n' +
      '// - Assign attributes using a name=val enclosed in parens, \'(attr=\\"value\\")\'\\n' +
      '// - Comments in the model text, like this are ignored\\n' +
      '\\n' +
      '// Demo using Bootstrap 4 styled elements\\n' +
      'container-fluid\\n' +
      '  h2=HTML Generator Demo\\n' +
      '  p=The HTML Live Generator uses a shorthand syntax for quickly prototyping and generating HTML snippets. ObjGen includes support for styling elements using the Bootstrap v4.x toolkit and is used in this demo example.\\n' +
      '  a(href=\\"https://getbootstrap.com\\" target=\\"_blank\\")=Click here for Bootstrap documentation and reference\\n' +
      '  hr\\n' +
      '\\n' +
      '  h3 pb-3=Typography\\n' +
      '  row\\n' +
      '    col bg-light border rounded p-2\\n' +
      '      h1 = h1. Bootstrap Heading\\n' +
      '      h2 = h2. Bootstrap Heading\\n' +
      '      h3 = h3. Bootstrap Heading\\n' +
      '\\n' +
      '  h3 pt-4=Grid Layout\\n' +
      '  row mb-3\\n' +
      '    col bg-light border\\n' +
      '      p-3 = 1 of 2\\n' +
      '    col bg-light border\\n' +
      '      p-3 = 2 of 2\\n' +
      '  row\\n' +
      '    col bg-light border\\n' +
      '      p-3 = 1 of 3\\n' +
      '    col bg-light border\\n' +
      '      p-3 = 2 of 3\\n' +
      '    col bg-light border\\n' +
      '      p-3 = 3 of 3\\n' +
      '\\n' +
      '  h3 pt-4=Flex Layout\\n' +
      '  d-flex bg-light #flexDemoContainer\\n' +
      '    mr-auto p-2 border=1 of 3\\n' +
      '    p-2 border=2 of 3\\n' +
      '    p-2 border=3 of 3\\n' +
      '\\n' +
      '  h3 pt-4=Forms\\n' +
      '    small=(Login Form)\\n' +
      '  row\\n' +
      '    col\\n' +
      '      form bg-light border rounded p-3\\n' +
      '        form-group\\n' +
      '          label=Email\\n' +
      '          input form-control (type=\\"email\\", placeholder=\\"someone@example.com\\")\\n' +
      '        form-group\\n' +
      '          label=Password\\n' +
      '          input form-control (type=\\"password\\", placeholder=\\"********\\")\\n' +
      '        form-group form-check\\n' +
      '          input form-check-input (type=\\"checkbox\\")\\n' +
      '          label form-check-label=Remember me\\n' +
      '        button btn btn-primary(type=\\"button\\")=Submit\\n' +
      '\\n' +
      '  h3 pt-4=Cards\\n' +
      '    small=(with decks)\\n' +
      '  card-deck\\n' +
      '    card\\n' +
      '      img card-img-top(src=\\"https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png\\")\\n' +
      '      card-body\\n' +
      '        h5 card-title=Card 1 Title\\n' +
      '        p=Card 1 Content\\n' +
      '      card-footer\\n' +
      '        small text-muted=Last updated 5 mins ago\\n' +
      '    card\\n' +
      '      img card-img-top(src=\\"https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png\\")\\n' +
      '      card-body\\n' +
      '        h5 card-title=Card 2 Title\\n' +
      '        p=Card 2 Content\\n' +
      '      card-footer\\n' +
      '        small text-muted=Last updated 5 mins ago\\n' +
      '    card\\n' +
      '      img card-img-top(src=\\"https://upload.wikimedia.org/wikipedia/commons/8/89/Portrait_Placeholder.png\\")\\n' +
      '      card-body\\n' +
      '        h5 card-title=Card 3 Title\\n' +
      '        p=Card 3 Content\\n' +
      '      card-footer\\n' +
      '        small text-muted=Last updated 5 mins ago\\n' +
      '\\n' +
      '  h2 pt-4=List Group\\n' +
      '    small=(with badges)\\n' +
      '\\n' +
      '  ul list-group\\n' +
      '    li list-group-item d-flex justify-content-between align-items-center active=Item 1\\n' +
      '      span badge badge-danger badge-pill=100\\n' +
      '    li list-group-item d-flex justify-content-between align-items-center=Item 2\\n' +
      '      span badge badge-primary badge-pill=75\\n' +
      '    li list-group-item=Item 3\\n' +
      '    li list-group-item=Item 4\\n' +
      '\\n' +
      '  h2 pt-4=Tables\\n' +
      '\\n' +
      '  table .table table-bordered\\n' +
      '    thead thead-light\\n' +
      '      tr\\n' +
      '        th=ID\\n' +
      '        th=First\\n' +
      '        th=Last\\n' +
      '        th=Email\\n' +
      '    tbody\\n' +
      '      tr\\n' +
      '        td=1\\n' +
      '        td=John\\n' +
      '        td=Doe\\n' +
      '        td=john.doe@example.com\\n' +
      '      tr\\n' +
      '        td=2\\n' +
      '        td=Jane\\n' +
      '        td=Doe\\n' +
      '        td=jane.doe@example.com\\n' +
      '      tr\\n' +
      '        td=3\\n' +
      '        td=Dave\\n' +
      '        td=Kingman\\n' +
      '        td=dave@davekingman.com\\n' +
      '\\n' +
      '  h2 mt-4=Alerts\\n' +
      '  alert alert-primary mt-3 mb-3=Primary Alert\\n' +
      '  alert alert-secondary mt-3 mb-3=Secondary Alert\\n' +
      '  alert alert-success mt-3 mb-3=Success! Thank you for viewing this demo!\\n'
      ;

    return demo;
  }

  generate(model) {
    return this.xBootstrap(model);
  }

  xBootstrap(modelText, options, callback) {
    var reggie = /([-\w]+)|(\.[-\w]+)|(#[-\w]+)|(\(.*?\))|(=.*$)/g;
    var elements = [];
    var parents = [];

    // parse the inbound model text
    ObjGen.parseLines(modelText, {numSpaces: 2}, function(line, depth) {
      if(line.match('^s+$/|^/$|^//|^s.*/$|^s.*//') !== null) {
        return '';
      }

      var elem = Html.createDomElement();
      var parent;
      if(depth === 1) {
        parent = null;
        parents = [];
        parents.push(elem);
      } else {
        while(parents.length > depth - 1) {
          parent = parents.pop();
        }
        parents.push(parent);
        parents.push(elem);
      }

      if(parent === null) {
        elements[elements.length] = elem;
      } else if(is.array(parent.elements)) {
        parent.elements[parent.elements.length] = elem;
      }

      parents.push(elem);

      var matches = 0;
      var match = reggie.exec(line);

      while(match !== null) {
        matches += 1;
        if(matches === 1 && Html.isHtmlElement(match[0])) {
          elem.kind = match[0];
        } else if(match[2]) {
          elem.clazz[elem.clazz.length] = match[0].replace(/\./, "");
        } else if(match[3]) {
          elem.id = match[0].replace(/#/, "");
        } else if(match[4]) {
          elem.attributes = match[0].replace(/^\(|\)$/g, "");
          elem.attributes = elem.attributes.trim().replace(/\s+/g, " ");
        } else if(match[5]) {
          elem.content = match[0].replace(/=/, "").trim();
        } else {
          elem.clazz[elem.clazz.length] = match[0];
        }

        match = reggie.exec(line);
      }
    });

    var h = this.genHtml(elements, 1)
    if(callback != null) {
      callback(h);
    }

    return h;
  }

  genHtml(elements, depth) {
    if(depth == null) {
      depth = 1;
    }

    var t = this.indent(depth);
    var result = "";

    for(var i = 0; i < elements.length; i++) {
      var e = elements[i];
      result += t + '<' + e.kind;
      if(Html.hasContent(e.id)) {
        result += ' id="' + e.id + '"';
      }

      if(Html.hasContent(e.clazz)) {
        result += ' class="' + Html.renderArray(e.clazz) + '"';
      }

      if(Html.hasContent(e.attributes)) {
        result += ' ' + e.attributes;
      }

      result += '>';

      var hasChildren = Html.hasContent(e.elements);

      if(Html.hasContent(e.content)) {
        if(hasChildren) {
           result += '\n' + t + this.indent(1);
        }
        result += e.content;
      }

      if(hasChildren) {
        result += '\n' + this.genHtml(e.elements, depth + 1) + t;
      }

      result += '</' + e.kind + '>\n';
    }

    return result;
  }

  static hasContent(val) {
    return is.existy(val) && val.length > 0;
  }

  static isHtmlElement(val) {
    return htmlElements.hasOwnProperty(val);
  }

  static createDomElement(e) {
    let elem = {
      kind: 'div',
      id: '',
      clazz: [],
      content: '',
      elements: [],
      attrbutes: []
    };

    if(is.existy(e)) {
      elem.kind = e.kind;
      elem.id = e.id;
      elem.clazz = e.clazz;
      elem.content = e.content;
      elem.elements = e.elements;
      elem.attrbutes = e.attributes;
    }

    return elem;
  }

  static elemToString(elem) {
    let s = '';

    if(Html.hasContent(elem.kind)) {
      s = this.kind;
    }

    if(Html.hasContent(elem.id)) {
      s += '#' + elem.id;
    }

    for(var i = 0; elem.clazz.length; i++) {
      s += '.' + elem.clazz[i];
    }

    return s;
  }

  indent(depth) {
    let s = '';

    for(let i = 0; i < depth; i++) {
      s += '  ';
    }

    return s;
  }

  static renderArray(a) {
    let s = '';

    for(let i = 0; i < a.length; i++) {
      if(s.length > 0) {
        s += ' ';
      }

      s += a[i];
    }

    return s;
  }
}

export default Html;
