const is = require('is_js');

export default class ProjectData {

  constructor() {
    this.syntaxes = {
      json: this.createNode('json', 'JSON', 'folder', '/json', []),
      html: this.createNode('html', 'HTML', 'folder', '/html', [])
    };

    this._nodes = [this.syntaxes.json, this.syntaxes.html];
  }

  get nodes() {
    return this._nodes;
  }

  createNode(id, name, type, path, children) {
    let node = {
      id: id || null,
      name: name || null,
      type: type || null,
      path: path || null,
      active: false,
      decorators: null,
      toggled: false,
      generator: null,
      local: true,
      loading: false,
      animations: null
    };

    if(is.array(children)) {
      node.children = children;
    }

    return node;
  }

  addNode(parentId, node, fromNodes) {
    if(is.not.existy(parentId)) {
      this._nodes.push(node);
      return;
    }

    fromNodes = fromNodes || this._nodes;

    for(let curNode of fromNodes) {
      if(curNode.id === parentId) {
        if(is.not.array(curNode.children)) {
          curNode.children = [];
        }

        curNode.children.push(node);
        break;
      }

      if(is.array(curNode.children) && curNode.children.length > 0) {
        this.addNode(parentId, node, curNode.children);
      }
    }
  }

  findFolderById(id, fromNodes) {
    let found = null;

    fromNodes = fromNodes || this._nodes;

    for(let node of fromNodes) {
      if(node.type === 'folder' && node.id === id) {
        found = node;
      }

      if(is.not.existy(found) && is.array(node.children) && node.children.length > 0) {
        found = this.findNodeById(id, node.children);
      }
    }

    return found;
  }

  findNodeById(id, fromNodes) {
    let found = null;

    fromNodes = fromNodes || this._nodes;

    for(let node of fromNodes) {
      if(node.id === id) {
        found = node;
      }

      if(is.not.existy(found) && is.array(node.children) && node.children.length > 0) {
        found = this.findNodeById(id, node.children);
      }
    }

    return found;
  }

  findNodeByName(name, fromNodes) {
    let found = null;

    fromNodes = fromNodes || this._nodes;
    name = name.toLowerCase();

    for(let node of fromNodes) {
      let nodeName = node.name || '';
      nodeName = nodeName.toLowerCase();

      if(nodeName === name) {
        found = node;
      }

      if(is.not.existy(found) && is.array(node.children) && node.children.length > 0) {
        found = this.findNodeByName(name, node.children);
      }
    }

    return found;
  }

  findNodeByPath(path, fromNodes) {
    let found = null;

    fromNodes = fromNodes || this._nodes;

    for(let node of fromNodes) {
      if(node.path === path) {
        found = node;
      }

      if(is.not.existy(found) && is.array(node.children) && node.children.length > 0) {
        this.findNodeByPath(path, node.children);
      }
    }

    return found;
  }

  selectPath(path, fromNodes, parentNode) {
    fromNodes = fromNodes || this._nodes;

    for(let node of fromNodes) {
      if(is.existy(parentNode)) {
        parentNode.toggled = true;
        parentNode.active = false;
      }

      node.active = node.path === path;

      if(is.array(node.children) && node.children.length > 0) {
        this.selectPath(path, node.children, node);
      }
    }
  }

  getNextNodeNumber(inPath, prefix) {
    let next = 1;
    let node = this.findNodeByPath(inPath);

    if(is.array(node.children) && node.children.length > 0) {
      for(let child of node.children) {
        let name = child.name;
        if(is.existy(name) && name.startsWith(prefix)) {
          let parts = name.split(prefix);
          if(parts.length > 1) {
            let n = Number.parseInt(parts[1], 10);
            if(is.number(n)) {
              next = Math.max(next, n) + 1;
            }
          }
        }
      }
    }

    return next;
  }

  load() {
    let syntaxes = this.syntaxes;
    let nodes = this._nodes;

    for(let node of nodes) {
      node.children.length = 0;
    }

    return new Promise((resolve, reject) => {
      try {
        for(let i = 0; i < localStorage.length; i++) {
          let key = localStorage.key(i);
          let keyParts = key.split('.');
          let syntaxType = keyParts[0];
          let modelId = keyParts[1];

          if(syntaxes.hasOwnProperty(syntaxType)) {
            let item = JSON.parse(localStorage.getItem(key));
            let node = this.createNode(syntaxType + '.local.' + modelId,
              item.name, 'model', '/' + syntaxType + '/local/' + modelId);

            node.generator = syntaxType;
            node.model = item.model;
            syntaxes[syntaxType].children.push(node);
          }
        }

        for(let node of nodes) {
          let designNode = null;

          for(let child of node.children) {
            if(child.id.endsWith('.design')) {
              designNode = child;
              break;
            }
          }

          if(designNode === null) {
            designNode = this.createNode(node.id + '.local.design',
              '(Design)', 'model', '/' + node.id + '/local/design');

            designNode.generator = node.id;
            designNode.model = '';

            node.children.push(designNode);
          }

          this.sort(node.children);
        }

        resolve(nodes);
      } catch(error) {
        reject('Unable to load data: ' + error);
      }
    });
  }

  sort(nodes) {
    if(is.array(nodes.children) && nodes.children.length > 0) {
      this.sort(nodes.children);
    }

    nodes.sort((a, b) => {
      if(a.id.endsWith('.design')) {
        return -1;
      } else if(b.id.endsWith('.design')) {
        return 1;
      } else {
        let aName = a.name || '';
        return aName.localeCompare(b.name);
      }
    });
  }
}
