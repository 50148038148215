import React, { Component } from 'react';

import {
  Card,
  CardBody,
  CardHeader,
  Container,
  Row,
  Col
} from 'reactstrap';

import './App.css';

class Home extends Component {
  render() {
    return (
      <div className="content">
        <Container className="home">
          <Row>
            <Col className="features-col features-summary" sm={{size: 10, offset: 1}} md="6">
              <h1>ObjGen - Live Code Generation for the Web</h1>
              <p>
                ObjGen is a live code generator that lets you see your code take
                shape as you enter in the names, types, properties and elements you define.
              </p>
              <p>
                Copy the generated code to your clipboard and then
                paste into a text editor of your choice.
              </p>
              <p>
                Save, share and version your models with a
                premium ObjGen subscription.
              </p>
              <p>
                Getting started is easy!
              </p>
              <p>
                Choose a live code generator from the list below:
              </p>
              <ul>
                <li><a href="/json">JSON Generator</a></li>
                <li><a href="/html">HTML Generator</a></li>
              </ul>
            </Col>
            <Col className="features-col" sm={{size: 10, offset: 1}} md={{size: 4, offset: 1}}>
              <Card>
                <CardHeader><h2>Features & Benefits</h2></CardHeader>
                <CardBody>
                  <ul>
                    <li>Easy to use</li>
                    <li>Code generates in real time as you type</li>
                    <li>Models are defined using a simple text based syntax</li>
                    <li>Use the Enter and Tab keys to define complex models and structures</li>
                    <li>Learn by example as code generates based on your model definition</li>
                    <li>ObjGen is an excellent addition to your tool box for quickly building demos and prototyping</li>
                    <li>One click clipboard copy support of the generated code</li>
                  </ul>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Home;
