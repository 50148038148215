import { ObjGen } from 'objgen/objgen.js';
import { GenEdder } from './GenEdder.js';

class Json extends GenEdder {

  get title() {
    return 'JSON';
  }

  get syntaxLanguage() {
    return 'json';
  }

  get demoModel() {
    let demo = '// Model & generate Live JSON data values\\n' +
      '// interactively using a simple syntax.\\n' +
      '// String is the default value type\\n' +
      'product = Live JSON generator\\n' +
      '\\n' +
      '// Number, Date & Boolean are also supported\\n' +
      '// Specify types after property names\\n' +
      'version n = 3.1\\n' +
      'releaseDate d = 2014-06-25\\n' +
      'demo b = true\\n' +
      '\\n' +
      '// Tabs or spaces define complex values\\n' +
      'person\\n' +
      '  id number = 12345\\n' +
      '  name = John Doe\\n' +
      '  phones\\n' +
      '    home = 800-123-4567\\n' +
      '    mobile = 877-123-1234\\n' +
      '\\n' +
      '  // Use [] to define simple type arrays\\n' +
      '  email[] s = jd@example.com, jd@example.org\\n' +
      '  dateOfBirth d = 1980-01-02\\n' +
      '  registered b = true\\n' +
      '\\n' +
      '  // Use [] or [n] to define object arrays\\n' +
      '  emergencyContacts[]\\n' +
      '    name s = Jane Doe\\n' +
      '    phone s = 888-555-1212\\n' +
      '    relationship = spouse\\n' +
      '  emergencyContacts[]\\n' +
      '    name s = Justin Doe\\n' +
      '    phone s = 877-123-1212\\n' +
      '    relationship = parent\\n' +
      '\\n' +
      '// See our Help page for additional info\\n' +
      '// We hope you enjoy the tool!\\n';

    return demo;
  }

  generate(model) {
    return ObjGen.xJson(model, { numSpaces: 2 });
  }
}

export default Json;
