import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import {
  Alert,
  Container
} from 'reactstrap';

import './App.css';

const is = require('is_js');
const queryString = require('query-string');

class Model404 extends Component {
  constructor(props) {
    super(props);

    let parsedQueryParams = queryString.parse(props.location.search);

    this.state = {
      syntax: is.existy(parsedQueryParams.syntax) ? parsedQueryParams.syntax : 'json',
      modelId: parsedQueryParams.modelId
    }
  }

  render() {
    return (
      <div className="content">
        <Container>
          <Alert color="danger">
            Model not found. <a href={ '/' + this.state.syntax } className="alert-link">Click here</a> to find or create a new model.
          </Alert>
        </Container>
      </div>
    );
  }
}

export default withRouter(Model404);
