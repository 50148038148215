import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Treebeard } from 'react-treebeard';
import TreeStyle from './TreeStyle';

import './App.css';

const is = require('is_js');

class ProjectSpace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      user: null,
      projectNodes: props.projectNodes
    };

    this.onToggle = this.onToggle.bind(this);
  }

  componentDidMount() {
  }

  onToggle(node, toggled) {
    if(is.existy(this.state.cursor)) {
      let previousCursor = this.state.cursor;
      previousCursor.active = false;
    }

    node.active = true;
    if(node.children) {
      node.toggled = toggled;
    }

    this.setState({
      cursor: node
    });

    if(node.path) {
      this.props.history.push(node.path);
    }
  }

  render() {
    return (
      <Treebeard
        data={this.state.projectNodes}
        onToggle={this.onToggle}
        style={TreeStyle}
        animations={false} />
    );
  }
}

export default withRouter(ProjectSpace);
