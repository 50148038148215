import React, { Component } from 'react';

import {
  Router,
  Route,
  Switch
} from 'react-router-dom'

import ReactGA from 'react-ga';

import './App.css';

import AppNav from './AppNav.js';
import Error404 from './Error404.js';
import Model404 from './Model404.js';
import Home from './Home.js';
import Html from './Html.js';
import Json from './Json.js';
import Login from './Login.js';
import Signup from './Signup.js';

import { createBrowserHistory } from 'history';
const history = createBrowserHistory();

ReactGA.initialize('UA-16785369-2');

class App extends Component {
  render() {
    return (
      <Router history={ history }>
        <div className="app">
        <AppNav />
          <Switch>
            <Route exact path="/" component={ Home } />
            <Route path="/html/:modelLocation/:modelId" component={ Html } />
            <Route path="/html" component={ Html } />
            <Route path="/json/:modelLocation/:modelId" component={ Json } />
            <Route path="/json" component={ Json } />
            <Route path="/models/html/:modelId" component={ Html } />
            <Route path="/models/json/:modelId" component={ Json } />
            <Route exact path="/login" component={ Login } />
            <Route exact path="/signup" component={ Signup } />
            <Route exact path="/Model404" component={ Model404 } />
            <Route component={ Error404 } />
          </Switch>
        </div>
      </Router>
    );
  }
}

export default App;
