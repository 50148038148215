import React, { Component } from 'react';
import { withRouter, Redirect } from 'react-router-dom';

import {
  Alert,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';

import SplitterLayout from 'react-splitter-layout';
import 'react-splitter-layout/lib/index.css';

import SyntaxHighlighter from 'react-syntax-highlighter';
import Cookies from 'universal-cookie';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FaClipboard, FaClipboardCheck, FaCode, FaEye, FaFile, FaAngleRight, FaSave, FaTrash } from 'react-icons/fa';
import { githubGist } from 'react-syntax-highlighter/dist/esm/styles/hljs';

// eslint-disable-next-line
import { tabby } from 'jquery-tabby';

import ProjectSpace from './ProjectSpace.js';
import ProjectData from './ProjectData.js';

import './App.css';

const is = require('is_js');
const shortid = require('shortid');
const queryString = require('query-string');
const axios = require('axios');
const cookies = new Cookies();
const $ = window.$;

const designId = 'design';
const designName = '(Design)';
const untitledNamePrefix = '(Untitled-';
const untitledNameSuffix = ')';

export class GenEdder extends Component {
  constructor(props) {
    super(props);

    let parsedQueryParams = queryString.parse(props.location.search);
    let modelId = props.match.params.modelId;
    let showDemo = (is.not.existy(modelId) && parsedQueryParams.demo === 'true') || modelId === 'demo';
    let projectData = new ProjectData();
    let legacy = props.location.pathname.startsWith('/models/json/') || props.location.pathname.startsWith('/models/html/');
    let modelName = showDemo ? '(Demo)' : (legacy ? '(' + modelId + ')' : modelId);
    let modelLocation = legacy ? 'legacy' : props.match.params.modelLocation;

    let showPreview = cookies.get('showPreview');
    showPreview = (is.existy(showPreview) && showPreview.toLowerCase() === 'true') ? true : false;

    this.state = {
      legacy: legacy,
      modelLocation: is.existy(modelLocation) ? modelLocation : 'local',
      modelId: showDemo ? 'demo' : null,
      modelName: is.existy(modelName) && modelId !== designId ? modelName : designName,
      modelInput: '',
      modelRevert: '',
      genCode: '',
      showPreview: showPreview,
      showCode: !showPreview,
      showDemo: showDemo,
      projectData: projectData,
      modelFolder: projectData.findFolderById(this.syntaxLanguage),
      savingAs: false,
      modelError: null
    };

    if(!showDemo) {
      if(is.not.existy(modelId) || modelId === designId) {
        // setup design node
        modelId = designId;
        this.props.history.push('/' + this.syntaxLanguage +
          '/' + this.state.modelLocation + '/' + modelId);

        let node = projectData.createNode(
          this.syntaxLanguage + '.' + this.state.modelLocation + '.' + modelId,
          this.state.modelName,
          'model',
          '/' + this.syntaxLanguage + '/' + this.state.modelLocation + '/' + modelId);

        let designModelItem = localStorage.getItem(this.syntaxLanguage + '.' + designId);

        if(designModelItem !== null) {
          let designModel = JSON.parse(designModelItem);
          this.state.modelInput = designModel.model;
        } else {
          this.saveModel(this.state.modelLocation, modelId, this.state.modelName, this.state.modelInput);
        }

        node.generator = this.syntaxLanguage;
        node.model = this.state.modelInput;
      } else if(legacy) {
        let node = projectData.createNode(
          this.syntaxLanguage + '.' + this.state.modelLocation + '.' + modelId,
          this.state.modelName,
          'model',
          '/' + this.syntaxLanguage + '/' + this.state.modelLocation + '/' + modelId);

        node.generator = this.syntaxLanguage;
        node.model = this.state.modelInput;
      }

      this.state.modelId = modelId;
    }

    this.handleModelChange = this.handleModelChange.bind(this);
    this.handleSaveAsChange = this.handleSaveAsChange.bind(this);
    this.handleSaveAsSubmit = this.handleSaveAsSubmit.bind(this);
    this.handleCleanup = this.handleCleanup.bind(this);
    this.onDeleteClicked = this.onDeleteClicked.bind(this);
    this.onNewClicked = this.onNewClicked.bind(this);
    this.onSaveClicked = this.onSaveClicked.bind(this);
    this.onSaveAsClicked = this.onSaveAsClicked.bind(this);
    this.onSaveAsCancel = this.onSaveAsCancel.bind(this);
    this.onSaveAsCommit = this.onSaveAsCommit.bind(this);
    this.onShowDemoClicked = this.onShowDemoClicked.bind(this);
    this.hasPreview = false;
  }

  componentDidMount() {
    $(this.modelTextArea).tabby({tabString: '  '});
    window.addEventListener('beforeunload', this.handleCleanup);

    this.state.projectData.load();
    this.loadModel();

    this.modelTextArea.focus();
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.handleCleanup);
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.match.params.modelId !== prevProps.match.params.modelId) {
      let modelLocation = this.props.match.params.modelLocation;
      let modelId = this.props.match.params.modelId;

      this.setState({
        modelLocation: is.existy(modelLocation) ? modelLocation : 'local',
        modelId: modelId
      }, () => {
        this.loadModel(modelId);
      });
    }
  }

  handleCleanup() {
    // TODO: prompt dirty
    // this.s aveModel();
    console.log('TODO: handle cleanup');
  }

  handleModelChange(event) {
    let curModel = event.target.value;

    if(curModel !== this.state.modelInput) {
      let genned;

      try {
        genned = this.generate(curModel);
      } catch(error) {
        let message = 'Unable to generate (' + (error || 'Unknown error') + ')';
        genned = message + '\n' + this.state.genCode;
        console.log(message);
      }

      if(!this.isLegacy()) {
        this.saveModel(this.state.modelLocation, this.state.modelId, this.state.modelName, curModel);
      }

      this.setState(() => ({
        modelInput: curModel,
        modelCopied: false,
        genCodeCopied: false,
        genCode: genned
      }));
    }
  }

  isValidName(name) {
    return is.existy(name) && name.length > 1 ? name.match(/^[A-Za-z0-9]/) !== null : false;
  }

  handleSaveAsChange(event) {
    let name = event.target.value;

    this.setState(() => ({
      saveAsName: name,
      saveAsNameValid: this.isValidName(name),
      saveAsNameVisited: true,
      saveAsError: ''
    }));
  }

  handleSaveAsSubmit(event) {
    event.preventDefault();
    this.onSaveAsCommit();
  }

  togglePreviewState() {
    let showPreview = !this.state.showPreview;

    this.setState(() => ({
      showPreview: showPreview,
      showCode: !showPreview
    }));

    cookies.set('showPreview', !this.state.showPreview);
  }

  onDeleteClicked() {
    if(window.confirm('Are you sure you want to delete this model?')) {
      localStorage.removeItem(this.syntaxLanguage + '.' + this.state.modelId);

      this.state.projectData.load();

      this.props.history.push('/' + this.syntaxLanguage +
        '/local/' + designId);
    }
  }

  onSaveClicked() {
    if(this.isUnsavedModel()) {
      this.setState(() => ({
        saveAsName: !this.isDesign() && !this.isDemo() ? this.state.modelName : '',
        saveAsNameVisited: false,
        savingAs: true,
        saveAsError: ''
      }));
    } else {
      this.saveModel(this.state.modelLocation, this.state.modelId, this.state.modelName, this.state.modelInput);
    }
  }

  onSaveAsClicked() {
    let name = !this.isDesign() && !this.isDemo() ? this.state.modelName : '';

    this.setState(() => ({
      saveAsName: name,
      saveAsNameValid: this.isValidName(name),
      saveAsNameVisited: false,
      savingAs: true,
      saveAsError: ''
    }));
  }

  onSaveAsCancel() {
    this.setState(() => ({
      savingAs: false,
      saveAsNameVisited: false,
      saveAsError: ''
    }));
  }

  onSaveAsCommit() {
    let newName = this.state.saveAsName.trim();

    if(!this.isLegacy() && this.state.modelName === newName) {
      this.setState(() => ({
        savingAs: false,
        saveAsNameVisited: false,
        saveAsError: ''
      }));

      return;
    }

    let node = this.state.projectData.findNodeById(
      this.syntaxLanguage + '.' + this.state.modelLocation + '.' + this.state.modelId);

    let untitled = this.isUntitledModel();

    if(is.existy(node)) {
      let other = this.state.projectData.findNodeByName(newName, this.state.modelFolder.children);

      if(is.existy(other) && node.id !== other.id) {
        this.setState(() => ({
          saveAsError: 'Model "' + newName + '" already exists. Please enter a unique name.',
          saveAsNameValid: false
        }));

        return;
      }
    }

    if(untitled) {
      node.name = newName;
    } else if(!this.isLegacy()) {
      // restore original model state
      this.saveModel(this.state.modelLocation, this.state.modelId, this.state.modelName, this.state.modelRevert);
    }

    this.setState(() => ({
      modelName: newName,
      savingAs: false
    }), () => {
      if(!untitled) {
        this.makeNewModel(shortid.generate(), newName, this.state.modelInput);
      }
    });
  }

  onNewClicked() {
    let newModelId = shortid.generate();
    let newModelName = untitledNamePrefix +
      this.state.projectData.getNextNodeNumber('/' + this.syntaxLanguage, untitledNamePrefix) + untitledNameSuffix;
    this.makeNewModel(newModelId, newModelName);
  }

  onShowDemoClicked() {
    this.loadModel('demo');

    this.props.history.push('/' + this.syntaxLanguage +
      '?demo=true');
  }

  get title() {
    return '';
  }

  get preview() {
    return { __html: '<div>No Preview</div>' };
  }

  get syntaxLanguage() {
    return 'text';
  }

  get demoModel() {
    return '';
  }

  generate(model) {
    return 'Extending component has not implemented this method.';
  }

  loadModel(modelId) {
    modelId = modelId || this.state.modelId;

    let itemJson = null;

    if(modelId === 'demo') {
      this.setModelState(this.state.modelId, '{"model": "' + this.demoModel + '", "name":"(Demo)"}');
    } else if(this.isLocal()) {
      itemJson = localStorage.getItem(this.syntaxLanguage + '.' + modelId);
      this.setModelState(modelId, itemJson);
    } else if(this.isLegacy()) {
      axios.get('/api/models/' + this.syntaxLanguage + '/' + this.state.modelId).then((response) => {
        this.setModelState(this.state.modelId, JSON.stringify(response.data));
      });
    } else {
      // TODO: load from api when available
      console.log('TODO: load remote');
    }

    this.state.projectData.selectPath('/' + this.syntaxLanguage + '/' + this.state.modelLocation + '/' + modelId);
  }

  setModelState(modelId, itemJson) {
    let modelText = '';
    let modelName = this.state.modelName;
    let genned = '';

    if(itemJson !== null) {
      let item = JSON.parse(itemJson);
      modelText = item.model;
      modelName = item.name;
    }

    try {
      genned = this.generate(modelText);
    } catch(error) {
      let message = 'Unable to generate (' + (error || 'Unknown error') + ')';
      genned = message + '\n' + this.state.genCode;
      console.log(message);
    }

    this.setState(() => ({
      modelInput: is.existy(modelText) ? modelText : '',
      modelRevert: is.existy(modelText) ? modelText : '',
      modelName: is.existy(modelName) ? modelName : designName,
      modelCopied: false,
      genCodeCopied: false,
      genCode: genned,
      showDemo: modelId === 'demo',
      modelError: is.existy(modelId) && is.not.existy(itemJson) ? 404 : null
    }));
  }

  isDemo() {
    return this.state.showDemo;
  }

  isDesign() {
    return this.state.modelId === designId || this.state.modelName === designName;
  }

  isLegacy() {
    return this.state.legacy;
  }

  isLocal() {
    return this.state.modelLocation === 'local';
  }

  isUntitledModel() {
    return this.state.modelName.startsWith(untitledNamePrefix);
  }

  isUnsavedModel() {
    return this.isDesign() || this.isUntitledModel();
  }

  makeNewModel(newModelId, newModelName, newModelInput) {
    let projectData = this.state.projectData;

    let node = projectData.createNode(
      this.syntaxLanguage + '.local.' + newModelId,
      newModelName,
      'model',
      '/' + this.syntaxLanguage + '/local/' + newModelId);

    projectData.addNode(this.syntaxLanguage, node);

    this.setState(() => ({
      modelId: newModelId,
      modelLocation: 'local',
      modelName: newModelName,
      modelInput: newModelInput || '',
      modelRevert: newModelInput || '',
      modelCopied: false,
      genCodeCopied: false,
      genCode: '',
      legacy: false,
      showDemo: false,
      modelError: null
    }), () => {
      this.saveModel(this.state.modelLocation, newModelId, newModelName, this.state.modelInput);
      projectData.selectPath('/' + this.syntaxLanguage + '/' + this.state.modelLocation + '/' + newModelId);

      this.props.history.push('/' + this.syntaxLanguage +
        '/' + this.state.modelLocation + '/' + newModelId);
    });
  }

  saveModel(modelLocation, modelId, modelName, modelInput) {
    if(this.isLocal() && !this.isDemo()) {
      localStorage.setItem(this.syntaxLanguage + '.' + modelId, JSON.stringify({
        name: modelName,
        model: modelInput || ''
      }));
    } else if(!this.isLegacy()) {
      // TODO: save with api when available
      console.log('TODO: save remote: ' + modelLocation);
    }
  }

  render() {
    if(is.existy(this.state.modelError)) {
      return <Redirect to={{
        pathname: '/Model404',
        search: '?syntax=' + this.syntaxLanguage + '&modelId=' + this.state.modelId
      }} />
    }

    return (
      <div className="editor">
        <SplitterLayout primaryIndex={1} secondaryInitialSize={250}>
          <ProjectSpace projectNodes={this.state.projectData.nodes} />
          <SplitterLayout primaryIndex={0} percentage>
            <div className="model-wrapper">
              <div className="d-flex flex-wrap">
                <div className="flex-fill code-title">
                  <label className="mt-2">{(this.title ? this.title + ': ' : '') + this.state.modelName}</label>
                </div>
                <div className="m-2">
                  <ButtonToolbar>
                    <ButtonGroup className="mr-1 mb-1">
                      <Button color="primary" size="sm"
                        onClick={this.onNewClicked}>
                        New&nbsp;
                        <FaFile />
                      </Button>
                    </ButtonGroup>
                    { !this.isUnsavedModel() && !this.isLegacy() && !this.isDemo() &&
                    <ButtonGroup className="mr-1 mb-1">
                      <Button color="success" size="sm"
                        onClick={this.onSaveClicked}>
                        Save&nbsp;
                        <FaSave />
                      </Button>
                    </ButtonGroup>
                    }
                    <ButtonGroup className="mr-1 mb-1">
                      <Button color="success" size="sm"
                        onClick={this.onSaveAsClicked}>
                        Save As&nbsp;
                        <FaSave />
                      </Button>
                    </ButtonGroup>
                    { !this.isDesign() && !this.isLegacy() && !this.isDemo() &&
                    <ButtonGroup className="mr-1 mb-1">
                      <Button color="danger" size="sm"
                        onClick={this.onDeleteClicked}>
                        Delete&nbsp;
                        <FaTrash />
                      </Button>
                    </ButtonGroup>
                    }
                    <ButtonGroup className="mr-1 mb-1">
                      <CopyToClipboard text={this.state.modelInput}
                        onCopy={() => this.setState((state, props) => ({modelCopied: true}))}>
                        { this.state.modelCopied === true ?
                          <Button color="success" size="sm">Copied&nbsp;
                            <FaClipboardCheck />
                          </Button>
                          :
                          <Button color="secondary" size="sm">Copy&nbsp;
                            <FaClipboard />
                          </Button>
                        }
                      </CopyToClipboard>
                    </ButtonGroup>
                    <ButtonGroup className="mb-1">
                      <Button color="secondary" size="sm" href={'/' + this.syntaxLanguage + '?demo=true'}>
                        Demo
                        <FaAngleRight />
                      </Button>
                    </ButtonGroup>
                  </ButtonToolbar>
                </div>
              </div>
              { this.isLegacy() &&
              <Alert color="warning">
                <small>
                  This model was loaded from <a href="http://objgen.com/models/{this.syntaxLanguage}/{this.state.modelId}" target="_blank" rel="noopener noreferrer">
                    http://objgen.com/models/{this.syntaxLanguage}/{this.state.modelId}</a> and is readonly in the current beta version.
                    To edit this model in the new environment, please use the 'Save As' button in toolbar to edit and save locally.
                </small>
              </Alert>
              }
              <textarea wrap="off"
                ref={el => this.modelTextArea = el}
                value={this.state.modelInput}
                onKeyUp={this.handleModelChange}
                onChange={this.handleModelChange}
                readOnly={this.isLegacy()} />
            </div>
            <div className="model-wrapper">
              <div className="d-flex flex-wrap">
                <div className="flex-fill code-title">
                  <label className="mt-2">{this.title}</label>
                </div>
                <div className="m-2">
                  <ButtonToolbar>
                  { this.hasPreview ?
                  (
                    <React.Fragment>
                      <ButtonGroup className="mr-1 mb-1">
                        <Button size="sm" color={this.state.showPreview ? 'primary' : 'secondary'} active={this.state.showPreview}
                          onClick={() => { this.togglePreviewState() }}>
                          Preview &nbsp;
                          <FaEye />
                        </Button>
                      </ButtonGroup>
                      <ButtonGroup className="mr-1 mb-1">
                        <Button size="sm" color={this.state.showCode ? 'primary' : 'secondary'} active={this.state.showCode}
                          onClick={() => { this.togglePreviewState() }}>
                          Code &nbsp;
                          <FaCode />
                        </Button>
                      </ButtonGroup>
                    </React.Fragment>
                    ) : null
                    }
                    <ButtonGroup className="mb-1">
                      <CopyToClipboard text={this.state.genCode}
                        onCopy={() => this.setState((state, props) => ({genCodeCopied: true}))}>
                        { this.state.genCodeCopied === true ?
                          <Button color="success" size="sm">Copied&nbsp;
                            <FaClipboardCheck />
                          </Button>
                          :
                          <Button color="secondary" size="sm">Copy&nbsp;
                            <FaClipboard />
                          </Button>
                        }
                      </CopyToClipboard>
                    </ButtonGroup>
                  </ButtonToolbar>
                </div>
              </div>
              <div className="gen-container">
                { this.hasPreview && this.state.showPreview === true ? (
                  <div dangerouslySetInnerHTML={ this.preview } />
                ) : null}
                { !this.hasPreview || this.state.showCode === true ? (
                  <SyntaxHighlighter language={ this.syntaxLanguage } style={githubGist}>
                    { this.state.genCode }
                  </SyntaxHighlighter>
                ) : null}
              </div>
            </div>
          </SplitterLayout>
        </SplitterLayout>
        <Modal isOpen={this.state.savingAs} toggle={this.onSaveAsCancel}>
          <ModalHeader toggle={this.onSaveAsCancel}>Save Model As</ModalHeader>
          <ModalBody>
            <Form onSubmit={this.handleSaveAsSubmit}>
              <FormGroup>
                <Label for="saveAsName">Model Name</Label>
                <Input type="text" name="saveAsName" id="saveAsName"
                  required minLength="2" maxLength="80"
                  value={this.state.saveAsName}
                  onChange={this.handleSaveAsChange}
                  valid={this.state.saveAsNameValid}
                  className={this.state.saveAsNameVisited ? (this.state.saveAsNameValid ? 'is-valid' : 'is-invalid') : ''} />
                <div className="invalid-feedback">{this.state.saveAsError}</div>
              </FormGroup>
            </Form>
            { (this.isLocal() || this.isLegacy()) &&
            <Alert color="warning">
              This version of ObjGen is currently running in local mode with model definitions
              saved to your browser's local storage. Please be sure to save a copy of your model
              text to avoid losing any work if your browser's history and application data are cleared.
            </Alert>
            }
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.onSaveAsCommit} >Save</Button>{' '}
            <Button color="secondary" onClick={this.onSaveAsCancel}>Cancel</Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default withRouter(GenEdder);
