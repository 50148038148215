import React, { Component } from 'react';

import {
  Alert,
  Container
} from 'reactstrap';

import './App.css';

class Error404 extends Component {
  render() {
    return (
      <div className="content">
        <Container>
          <Alert color="warning">
            <h4 className="alert-heading">Page Not Found</h4>
            <p>The requested page or resource was not found. Please be sure you've entered the correct link and try again.</p>
            <hr />
            <p><a href="/">Click here</a> to return to the Home page</p>
          </Alert>
        </Container>
      </div>
    );
  }
}

export default Error404;
