import React, { Component } from 'react';

import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col
} from 'reactstrap';

import './App.css';

class Signup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      userId: '',
      password: '',
      validate: {
        emailState: '',
      },
    };

    this.onUserEmailChanged = this.onUserEmailChanged.bind(this);
    this.onUserIdChanged = this.onUserIdChanged.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  onUserEmailChanged(e) {
    this.setState({
      email: e.target.value
    });
  }

  onUserIdChanged(e) {
    this.setState({
      userId: e.target.value
    });
  }

  submitForm(e) {
    e.preventDefault();
    console.log(`Email: ${this.state.userId}`);
  }

  render() {
    return (
      <div className="content">
        <Container className="login">
          <Row>
            <Col>
              <h1>Sign Up for ObjGen - Live Code Generation for the Web</h1>
            </Col>
          </Row>
          <br />
          <Row>
            <Col className="features-col features-summary" sm="12" md="5">
              <Form onSubmit={this.submitForm}>
                <FormGroup>
                  <Label for="userId">Enter your Email Address</Label>
                  <Input type="email" name="email" id="email"
                         value={this.state.email} required
                         onChange={this.onUserEmailChanged} />
                </FormGroup>
                <FormGroup>
                  <Label for="userId">Enter a User Id</Label>
                  <Input type="text" name="userId" id="userId"
                         value={this.state.userId} required
                         minLength="3" maxLength="16"
                         onChange={this.onUserIdChanged} />
                </FormGroup>
                <FormGroup>
                  <Label for="password">Set Password</Label>
                  <Input type="password" name="password" id="password" minLength="8" />
                </FormGroup>
                <FormGroup>
                  <Row>
                    <Col sm="12">
                      <Button color="success" block className="mt-2">Create Account</Button>
                    </Col>
                  </Row>
                </FormGroup>
                <FormGroup>
                  <a href="/login">Already have an account? Click here to login.</a>
                </FormGroup>
              </Form>
            </Col>
            <Col className="features-col" sm="12" md="6 offset-md-1">
              <Card>
                <CardHeader><h2>Features & Benefits</h2></CardHeader>
                <CardBody>
                  <ul>
                    <li>Easy to use</li>
                    <li>Code generates in real time as you type</li>
                    <li>Models are defined using a simple text based syntax</li>
                    <li>Use the Enter and Tab keys to define complex models and structures</li>
                    <li>Learn by example as code generates based on your model definition</li>
                    <li>ObjGen is an excellent addition to your tool box for quickly building demos and prototyping</li>
                    <li>One click clipboard copy support of the generated code</li>
                  </ul>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Signup;
