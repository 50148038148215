import React, { Component } from 'react';
import './App.css';

import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown
} from 'reactstrap';

class AppNav extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false
    };
  }

  toggle() {
    this.setState((state, props) => ({
      isOpen: !state.isOpen
    }));
  }

  render() {
    return (
      <Navbar color="dark" className="navbar-dark navbar-expand-sm" expand="md" fixed="top">
        <NavbarBrand href="/">
          ObjGen
          <Media object className="logo" src="/objgen-logo.png" alt="" width="24" height="24"></Media>
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>Demos</DropdownToggle>
              <DropdownMenu right>
                <DropdownItem tag="a" href="/json?demo=true">JSON Generator Demo</DropdownItem>
                <DropdownItem tag="a" href="/html?demo=true">HTML Generator Demo</DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              <NavLink href="/login/">Login</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    );
  }
}

export default AppNav;
